<template>
  <input id="demo" placeholder="Please Select ..." />
</template>

<script>
// import SectionHeader from '../../../common/components/booking/SectionHeader';
// import ButtonList from '../../../common/components/booking/ButtonList';
// import uploadIcon from '../../../assets/Icons/upload.svg';
// import errorIcon from '../../../assets/Icons/errorIcon.svg';
// import deleteIcon from '../../../assets/Icons/deleteButton.svg';
// import LuggageService from '../../../services/LuggageService';
import VueSweetalert2 from "sweetalert2";
import vuex from "vuex";
//importing bootstrap 5 Modules
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import Resumable from "resumablejs";
// import mobiscroll from '../plugins/timePicker/js/mobiscroll.javascript.min';

export default {
  name: "LuggageDetails",
  data: function () {
    return {
      actionName: "save",
      buttonName: "Next Step",
      // uploadIcon,
      // deleteIcon,
      // errorIcon,
      luggageDetails: this.$store.getters.getluggageDetails,
      luggagePhotos: this.$store.getters.getluggagePhotos,
      verification: this.$store.getters.getVerification,
      lType: "",
      lHeight: "",
      lWidth: "",
      lDepth: "",
      lTotalCubic: "",
      weight: "",
      qty: "",
      luggageData: [],
      currentLuggageDetails: [],
      savedData: [],
      img: "",
      luggageImageDetails: [],
      errors: {},
      handlingInstructions: [],
      imageUrl: "",
    };
  },
  async mounted() {
    //this.initResumable();
    $("#demo").mobiscroll().datetime();
  },
  computed: {
    ...vuex.mapGetters(["getluggageInputDetails"]),
    ...vuex.mapGetters(["getLuggageImageDetails"]),
  },
  methods: {
    ...vuex.mapActions(["setLuggageDetails"]),

    initResumable: function (element) {
      let portalid = 1;
      let projectid = 1;
      let dnnInstallationpath = "";
      // setTimeout(() => {
      //   const container = this.$el.querySelector('passportImg');
      //   container.scrollTop = container.scrollHeight;
      // }, 3000);
      let uploaderimage = document.getElementById(element);

      // let uploaderimage = document.querySelector('passportImage01')
      let imagetype = ["bmp", "gif", "png", "jpeg", "JPG"];
      //this.resumable(imagetype, 'http://localhost:61709/api/FileUpload/upload', portalid, uploaderimage, 2, projectid,dnnInstallationpath)
      this.resumable(
        imagetype,
        "http://login.emerge.lk:9090/MyPorterFileUploaderAPI/api/FileUploader/upload",
        portalid,
        uploaderimage,
        2,
        projectid,
        dnnInstallationpath
      );
    },

    resumable(
      filetype,
      target,
      portalid,
      element,
      type,
      projectid,
      dnnInstallationpath
    ) {
      let self = this;
      let resumableObject = {
        target: target,
        query: {
          PortalId: portalid,
          ProjectId: projectid,
          dnnInstallationPath: dnnInstallationpath,
        },
        simultaneousUploads: 10,
        fileType: filetype,
        maxFiles: 10,
      };

      let r = new Resumable(resumableObject);
      r.assignBrowse(element);
      r.on("progress", function () {
        console.debug("progress");
      });

      r.on("fileAdded", function (file) {
        var filesize = Math.round(file.size / 1048576);
        if (filesize < 71) {
          self.isUploading = true;
          self.isPosted = true;
          if (type == 3) {
            r.upload();
            // if(gtDocsValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          } else if (type == 1) {
            r.upload();
            //  if(gtVideoValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          } else if (type == 2) {
            r.upload();
            // if(gtGraphicsValidator(file))
            // {
            //    r.upload()
            // }
            // else{
            //   self.clearFileUplloadStuff();
            //   self.eventBus.$emit('showMessage', '.'+self.tempFileName.split('.').pop()+' file extension is not supported', 'warn')
            // }
          }
        } else {
          //  self.clearFileUplloadStuff();
          //  self.eventBus.$emit('showMessage', 'File is too large. Please upload a file which is less than 70MB', 'warn')
        }
      });

      r.on("fileSuccess", function (file, message) {
        var tempFileName = file;
        this.imageUrl = tempFileName;

        self.tempAttachmentName = file.fileName;
        self.newPost.PostMediaType = type;
        self.newPost.PostMediaURL = message.replace(/"/g, "");
        self.uploading = false;
        self.btnShowAdd = true;
        self.isUploading = false;
      });

      r.on("fileError", function (file) {
        this.imageUrl = "http://myporterqa.emerge.lk/img/bg/contact.png";
        //  self.clearFileUplloadStuff();
        // self.eventBus.$emit('showMessage', 'An error occurred', 'warn')
      });
    },

    checkMinusQTYValues(event, index) {
      if (parseInt(event.target.value.split("")[0]) === 0) {
        event.target.value = event.target.value.split("")[1];
      }

      const table = $("#luggageDetailsTableBody tr");
      table[index].children[2].children[0].children[1].textContent = "";
      if (event.target.value < 0 || event.target.value === "-0") {
        table[index].children[2].children[0].children[1].textContent =
          "Please enter a valid value";
      } else {
        table[index].children[2].children[0].children[1].textContent = "";
      }
    },
    checkMinusValues(event, index) {
      if (parseInt(event.target.value.split("")[0]) === 0) {
        event.target.value = event.target.value.split("")[1];
      }
      const table = $("#luggageDetailsTableBody tr");

      table[index].children[1].children[0].children[1].textContent = "";
      if (event.target.value < 0 || event.target.value === "-0") {
        table[index].children[1].children[0].children[1].textContent =
          "Please enter a valid value";
      } else {
        table[index].children[1].children[0].children[1].textContent = "";
      }
    },
    setLuggagePhoto(index) {
      let luggageImageTBody = $("#lugPhotoTBody tr");
      const inputFile =
        luggageImageTBody[index].children[3].children[0].children[1]
          .children[0];

      $(inputFile).on("change", function () {
        const file = this.files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
          luggageImageTBody[index].children[3].children[0].children[0].src =
            e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },
    showComfirmation() {
      let check = document.getElementById("all").checked;
      if (check) {
        const allAgreement = document.getElementsByName("verificationAgree");
        let checkOnceNotAgrred = true;
        for (const checkbox of allAgreement) {
          if (!checkbox.checked) {
            checkOnceNotAgrred = false;
            break;
          }
        }
        if (checkOnceNotAgrred) {
          document
            .getElementById("confirmationMainSec")
            .classList.remove("d-none");
          document
            .getElementById("confirmationMainSec")
            .classList.add("d-block");
        } else {
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            title: "Please select all verifications",
            icon: "warning",
          });
        }
      } else {
        const allAgreement = document.getElementsByName("verificationAgree");
        for (const checkbox of allAgreement) {
          checkbox.checked = true;
        }
        VueSweetalert2.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          title: "Please select all verifications",
          icon: "warning",
        });
      }
    },
    showVerification() {
      // alert('Your luggage details have been saved');
      const luggageDetails = this.getluggageInputDetails;

      let luggageImageTBody = $("#lugPhotoTBody tr");
      let luggageImage = [];

      for (let i = 0; i < luggageImageTBody.length; i++) {
        const luggageDescription =
          luggageImageTBody[i].children[0].children[0].textContent;
        const luggageCode = luggageImageTBody[i].children[1].textContent;
        const luggageXXX =
          luggageImageTBody[i].children[2].children[0].children[0].value;

        const insurance = luggageImageTBody[i].children[4].children[0].checked;

        let luggageImageDetails = {
          luggageDescription: luggageDescription,
          luggageCode: luggageCode,
          luggageXXX: luggageXXX,
          luggageImg: "",
          insurance: insurance,
        };

        luggageImageDetails.luggageImg = this.imageUrl;

        luggageImage.push(luggageImageDetails);
      }

      this.$store.dispatch("setLuggageImageDetails", luggageImage);

      const LuggageDetailsArray = [];

      for (let i = 0; i < luggageDetails.length; i++) {
        luggageDetails[i].luggageImages = luggageImage;
        LuggageDetailsArray.push(luggageDetails[i]);
      }

      this.$store.dispatch("setLuggageImageDetails", LuggageDetailsArray);

      document.getElementById("verificationSec").classList.remove("d-none");
      document.getElementById("verificationSec").classList.add("d-block");
    },
    displayImage(img) {
      this.img = img;
    },
    setLuggageInsuranceAggrement(event) {
      if (event.target.checked) {
        const allAgreement = document.getElementsByName("insuranceInput");
        for (const checkbox of allAgreement) {
          checkbox.checked = event.target.checked;
        }
      } else {
        const allAgreement = document.getElementsByName("insuranceInput");
        for (const checkbox of allAgreement) {
          checkbox.checked = event.target.checked;
        }
      }
    },
    selectAllVerification() {
      let all = document.getElementById("all");
      if (all.checked === true) {
        const allAgreement = document.getElementsByName("verificationAgree");
        for (const checkbox of allAgreement) {
          checkbox.checked = true;
        }
      } else {
        const allAgreement = document.getElementsByName("verificationAgree");
        for (const checkbox of allAgreement) {
          checkbox.checked = false;
        }
      }
    },

    setLuggageDetailsToStorage() {
      let luggageDetails = document.getElementById("luggageDetailsTableBody");

      let luggageArray = [];
      let itemListCount = 0;
      for (const luggageDetail of luggageDetails.children) {
        let luggageData = {
          luggageID: 0,
          luggageDescription: "",
          luggageCode: "",
          luggageHeight: 0,
          luggageWidth: 0,
          luggageDepth: 0,
          luggageTotalCubic: 0,
          luggageQty: 0,
          luggageWeight: 0,
          isActive: true,
          createdDate: "2021-12-04T06:48:05.701Z",
          createdBy: 0,
          modifiedDate: "2021-12-04T06:48:05.701Z",
          luggageFee: 0,
          luggageTotalFee: 0,
          modifiedBy: 0,
          luggageImages: [],
          newitemId: 0,
        };

        let change = false;
        for (let i = 0; i < luggageDetail.children.length; i++) {
          switch (i) {
            case 0:
              luggageData.luggageDescription =
                luggageDetail.children[i].children[0].textContent;
              luggageData.luggageID = parseInt(
                luggageDetail.children[i].children[1].textContent
              );
              luggageData.luggageCode =
                luggageDetail.children[i].children[2].textContent;

              if (
                luggageDetail.children[i].children[0].textContent ===
                "Small Cabin"
              ) {
                luggageData.luggageFee = this.luggageData[0].luggageFee;
                luggageData.luggageTotalFee =
                  this.luggageData[0].luggageTotalFee;
              } else if (
                luggageDetail.children[i].children[0].textContent ===
                "Large Cabin"
              ) {
                luggageData.luggageFee = this.luggageData[1].luggageFee;
                luggageData.luggageTotalFee =
                  this.luggageData[1].luggageTotalFee;
              } else if (
                luggageDetail.children[i].children[0].textContent === "XL Cabin"
              ) {
                luggageData.luggageFee = this.luggageData[2].luggageFee;
                luggageData.luggageTotalFee =
                  this.luggageData[2].luggageTotalFee;
              } else if (
                luggageDetail.children[i].children[0].textContent ===
                "M Suitcase"
              ) {
                luggageData.luggageFee = this.luggageData[3].luggageFee;
                luggageData.luggageTotalFee =
                  this.luggageData[3].luggageTotalFee;
              } else {
              }
              break;
            case 1:
              luggageData.luggageWeight = parseInt(
                luggageDetail.children[i].firstChild.firstChild.value
              );

              break;
            case 2:
              luggageData.luggageQty = parseInt(
                luggageDetail.children[i].firstChild.firstChild.value
              );
              break;
            default:
              break;
          }
        }
        if (luggageData.luggageWeight <= 0 && luggageData.luggageQty <= 0) {
          change =
            luggageData.luggageWeight <= 0 && luggageData.luggageQty <= 0;
        } else if (luggageData.luggageWeight <= 0) {
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            title: "Please enter luggage weight",
            icon: "warning",
          });
          break;
        } else if (luggageData.luggageQty <= 0) {
          VueSweetalert2.fire({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            title: "Please enter luggage qty",
            icon: "warning",
          });
          break;
        } else {
          for (let j = 0; j < luggageData.luggageQty; j++) {
            luggageArray.push(luggageData);
          }
        }

        let lastArry = [];
        luggageArray.forEach((element) => {
          element.newitemId = ++itemListCount;
          lastArry.push(element);
        });
        debugger;
        this.setLuggageDetails(lastArry);

        if (luggageArray.length > 0) {
          document
            .getElementById("luggagePhotosSec")
            .classList.remove("d-none");
          document.getElementById("luggagePhotosSec").classList.add("d-block");
        } else {
          if (luggageData.luggageQty <= 0) {
            VueSweetalert2.fire({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 3000,
              title: "Please enter luggage qty",
              icon: "warning",
            });
          } else if (luggageData.luggageWeight <= 0) {
            VueSweetalert2.fire({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 3000,
              title: "Please enter luggage weight",
              icon: "warning",
            });
          } else if (change) {
            VueSweetalert2.fire({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 3000,
              title: "Please add atleast one luggage",
              icon: "warning",
            });
          }
        }
      }
      this.checkIndeterminate();
    },

    checkIndeterminate() {
      //const savedData = this.getluggageInputDetails;
      const savedData = [];
      var count = 0;
      // this.getluggageInputDetails.forEach((element,i) => {
      //   //element.Target.iemID = count
      //   //savedData.push(element.Target)
      //   this.$set(this.getluggageInputDetails[i], "itemNewID", count);
      //   count++
      // });

      this.savedData = this.getluggageInputDetails;

      let uploaderimage;
      setTimeout(() => {
        this.savedData.forEach((element) => {
          this.initResumable("luggageImg_" + element.newitemId);
        });
      }, 0);
    },
  },
};
</script>

<style scoped>
img[title]:hover::after {
  text-align: left;
}

input:focus {
  outline: none;
  border-color: #006203;
  box-shadow: none;
}

#flexCheckIndeterminateLabel {
  color: #3f434a;
}

select:focus {
  outline: none;
  border-color: #006203;
  box-shadow: none;
}

.verificationLabel {
  margin-top: 6px;
  margin-left: 15px;
  font-size: 15px;
}

input[type="checkbox"] {
  width: 24px;
  height: 24px;
}

input[type="number"],
input[type="text"] {
  border-radius: 10px;
}

.photoUploader {
  width: 150px;
  border: 1px solid #e8e9eb;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 13px;
}

.luggageDetailsDiv {
  padding-left: 135px;
  padding-right: 100px;
}

.luggagePhotosDiv {
  padding-left: 115px;
  padding-right: 60px;
}

.form-check-input:checked {
  background-color: #006203;
  border-color: #006203;
}

.captionText {
  color: #006203;
}

select {
  border-radius: 13px;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr {
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: left;
}

table th {
  height: 50px;
}

@media screen and (max-width: 768px) {
  .luggagePhotosDiv {
    padding-left: 0;
    padding-right: 0;
  }

  .photoUploader {
    width: 108px;
  }

  .luggageDetailsDiv {
    padding-left: 0;
    padding-right: 0;
  }

  #flexCheckIndeterminate {
    margin-right: 15px;
  }
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

@media screen and (max-width: 425px) {
  .luggageDetailsDiv {
    padding-left: 0;
    padding-right: 0;
  }

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  .luggagePhotosDiv {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
